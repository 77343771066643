import {
  collection,
  onSnapshot,
  orderBy,
  query,
  Query,
} from "firebase/firestore";
import { collectionData } from "rxfire/firestore";
import { SubmittedDocument, SubmittedDocuments } from "types/SubmittedDocument";
import { Subscription } from "rxjs";

export const useSampleDocuments = defineStore("/sample/documents/", () => {
  const sampleDocuments = ref<SubmittedDocuments>([]);

  const isInitialized = ref(false);
  const subscription = ref<Subscription | undefined>();

  const streamSampleDocuments = () => {
    if (isInitialized.value) return;

    const db = useFirestore();
    const sampleDocumentsRef = collection(db, "/sampleDocuments");
    const sampleDocumentsQuery = query(
      sampleDocumentsRef,
      orderBy("name", "asc")
    ) as Query<SubmittedDocument>;

    subscription.value?.unsubscribe();
    subscription.value = collectionData<SubmittedDocument>(
      sampleDocumentsQuery,
      { idField: "id" }
    ).subscribe((documents) => {
      sampleDocuments.value = documents;
    });

    isInitialized.value = true;
  };

  return {
    streamSampleDocuments,
    sampleDocuments,
  };
});
